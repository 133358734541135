div.sidebar{
        width: 250px; 
        background-color: #333; 
        color: white; 
        padding: 10px; 
        display: flex; 
        flex-direction: column;
        overflow-y:auto
}
iframe.template-thumbnail{
   
        background: white;
        border: 1px solid white;
        height: 130px; /* Ensure to include 'px' */
        width: 100%;
        overflow: hidden; /* This is equivalent to 'scrolling="no"' */
    
}
/* This class will be shown only on desktop */
.desktop-only {
        display: block; /* or whatever display type you need */
    }
    
    .mobile-only{
        display:none;
}
.floating-button {
        position: fixed;
        top: 20px;
        left: 20px;
        padding: 10px 15px;
        background-color: #007BFF;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        display: none; /* Hidden by default */
}
    
@media (max-width: 768px) {
.floating-button {
        display: block; /* Show on mobile devices */
}
}
.floating-button2 {
        position: fixed;
        top: 8px;
        right: 10px;
z-index: 999;
        border: none;
        cursor: pointer;
        display: none; /* Hidden by default */
}
    
@media (max-width: 768px) {
.floating-button2 {
        display: block; /* Show on mobile devices */
}
.mobile-only{
        display:block;
}
}
@media (max-width: 425px) {
    
        .gjs-pn-views-container{
         
                width: 50%;
        }
        .gjs-pn-views{
                width: 50%;
        
        }
        }


.gjs-pn-panel{
        overflow-x: auto;
}
.hidden{
        display:none !important;
}