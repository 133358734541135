
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    gap: 16px;
    padding: 16px;
  }
  
  .grid-item {
    position: relative;
    overflow: hidden;
  }
  
  .template-image {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.2s;
  }
  
  .grid-item:hover .template-image {
    transform: scale(1.05);
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .grid-item:hover .overlay {
    opacity: 1;
  }
  
  .btn {
    background-color: white;
    border: none;
    padding: 10px 15px;
    margin: 5px;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #f0f0f0;
  }
  .floating-button {
    position: fixed;
    top: 20px;
    left: 20px;
    padding: 10px 15px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: none; /* Hidden by default */
}

@media (max-width: 768px) {
    .floating-button {
        display: block; /* Show on mobile devices */
    }
}